<template>
  <div>
    <OrderDialog
      v-if="itemDialog.visible"
      :user_states="itemDialog.user_states || []"
      :uuidItem="itemDialog.item.uuid"
      :typeDialog="itemDialog.type"
      settingDialog="crm"
      no-cross
      :isCallCenter="true"
    />
    <div v-if="showImg" class="cabinet-del-state-list pa-0">
      <div class="d-flex align-center justify-content-center" style="height:inherit">
        <img style="filter: blur(0.6px);" src="@/assets/images/no_data_img.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderDialog from "../../orderDialog/OrderDialog.vue";

export default {
  props: {
    boardsItem: Object,
    itemDialog: Object,
    showImg: {
      require: true,
      default: true
    },
  },
  data: () => ({}),
  mounted() {
    //
  },
  watch: {
    itemDialog: {
      deep: true,
      handler(e) {

      }
    }
  },
  computed: {},
  components: {
    OrderDialog
  }
};
</script>

<style lang="scss">
.cabinet-del-state-list {
  min-height: 25vh;
  max-height: 80vh;
  height: 80vh;
  overflow-y: scroll;
}
.empty-image {
  position: absolute;
  width: 40%;
  opacity: 0.05;
}
</style>
